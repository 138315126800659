@font-face {
  font-family: 'ITCBerkeleyOldStyle';
  src: local('ITCBerkeleyOldStyle'), url(./fonts/ITCBerkeleyOldStyle/BerkeleyStd-Black.otf) format('opentype');
}

@font-face {
  font-family: 'ITCBerkeleyOldStyle';
  font-weight: 900;
  src: local('ITCBerkeleyOldStyle'), url(./fonts/ITCBerkeleyOldStyle/BerkeleyStd-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Universfonts';
  src: local('Universfonts'), url(./fonts/Universfonts/UniversLTStd.otf) format('opentype');
}

@font-face {
  font-family: 'Universfonts';
  font-weight: 900;
  src: local('Universfonts'), url(./fonts/Universfonts/UniversLTStd-Bold.otf) format('opentype');
}

body {
  overflow-x: hidden;
}


/* body {
  font-family:Universfonts;
  font-weight: 100;
}

h1 {
  font-family:ITCBerkeleyOldStyle;
} */

html {
  position: relative;
  min-height: 100%;
}

.h1color {
  color: #7C2529;
}

input,
nav-item {
  font-weight: 200;
  line-height: 110%;
}

.heightForPDF {
  height: 700px;
}

.bg-cover {
  background-size: cover !important;
}

.dropdown-toggle::after {
  margin-top: 0.5em;
  float: right;
}

/* .maintxt {position: relative;} */
/* .maintxt > img, .overlay-text {position: absolute;} */


/* Sticky footer styles
-------------------------------------------------- */
.footer {
  bottom: 0;
  width: 100%;
  border-top: 2px solid #c00;
  /* height: 60px; Set the fixed height of the footer here */
  /*line-height: 60px; /* Vertically center the text there */
  /* margin-top: 20px; */
  /* background-color:grey; */
}


.mxWidth {
  max-width: 1500px;
  margin: 0 auto !important;
  float: none !important;
}

.mnHeight {
  min-height: 800px;
}

.advance_search {
  display: none;
}

.disableProfile {
  pointer-events: none;
}

/* .bg-colorPantoneDark{
  background-color: #524727;
   
} */
.bg-colorPantone {
  background-color: lightgray;
}

input[type=checkbox] {
  transform: scale(1.2);
}
/* .bg-colorPantoneLite{
  background-color: #CAC7A7;
} */

.fs12 {
  font-size: 1.2rem;
}

.react-tabs__tab--selected {
  background-color: lightgray;
  color: blue;
}

.react-tabs__tab:hover {
  color: red;

}

.dropdown-menu {
  font-size: 14px;
}

.dropdown-menu>a:hover {
  /* background-image: none; */
  background-color: blue;
  color: white !important;

}

.frontText {
  position: relative;
  top: -90px;
  z-index: 100;
  font-size: 2.8em;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.7);
}

.fspTextandButton {
  top: 265px;
  position: absolute;
  z-index: 100;
  background: rgba(0, 0, 0, 0.65);
}

.fspTextandButton h1 {
  font-size: 2.8em;
}

.footerLogoWidth{
  width: 25%;
  height: 100%;
}
.foodsystemLogo{
  width: 80%;
}
@media only screen and (max-width: 600px) {
  .frontText {
    font-size: 1em;
    background: rgba(0, 0, 0, 0.7);
  }

  .fspTextandButton {
    top: -110px;
    position: relative;
    z-index: 100;
    background: rgba(0, 0, 0, 0.65);

  }

  .fspTextandButton h1 {
    font-size: 1em;
  }
  .footerLogoWidth{
    width: 50%;
    height: 50%;
  }
}

/* just to store */
.awssld {
  --organic-arrow-thickness: 4px;
  --organic-arrow-height: 40px;
  --slider-height-percentage: 35%;
  --loader-bar-color: #851515;
  --loader-bar-height: 6px;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-opacity: 0.5;
  --control-button-hover-opacity: 0.75;
  --control-button-background: transparent;
  --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
  --slider-transition-duration: 575ms;
  --organic-arrow-color: #6a6a6a;
  --organic-arrow-border-radius: 0;
  --control-bullet-color: #6a6a6a;
  --control-bullet-active-color: #6a6a6a;
  --content-background-color: #2f2f2f;
}

.leaflet-container {
  width: 100%;
  height: 500px;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}
.fa-user-alt {
  color: #C8102E;
}
.fa-user{
  color: #C8102E;
}

.fa-book-open{
  color: #F1BE48;
} 

.custom-ui {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: lightgray;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: black;
}

.custom-ui>h1 {
  margin-top: 0;
}

#eduContainer {
  line-height: 45px;
}

#eduContainer h4,
#eduContainer p {
  display: inline;
  vertical-align: top;
  line-height: 28px;
}

#eduContainer h4 {
  font-size: 1.3rem;
}

#eduContainer p {
  font-size: 1.2rem;
}

.info {
  padding: 6px 8px;
  background: white;
  border-radius: 5px;
}

.legend {
  text-align: left;
  line-height: 18px;
  color: #555;
}

.legend_color{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal;
  color: black;
}

.mapPopulateText{
  position: relative;
  top: 45px;
  font-size: 1.5em;
}

.leaflet-container {
  height: 390px;
}